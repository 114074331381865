<script setup lang="ts">
import type { HomeBanners } from "@/types";

const {
	public: { baseImageUrl }
} = useRuntimeConfig();
const { banner } = defineProps<{
	banner: HomeBanners[number];
}>();

const { isMobile } = useDevice();
const { registrationRewards, registrationRewardWithoutCode, isPersonalOffer } = useCheckRewardsData({
	immediate: false
});

const coins = computed(() => registrationRewards.value?.coins || registrationRewardWithoutCode.value?.coins);
const entries = computed(() => registrationRewards.value?.entries || registrationRewardWithoutCode.value?.entries);

const bgDesktop = computed(() => `url(${baseImageUrl}${banner?.secondImage2x})`);
const bgMobile = computed(() => `url(${baseImageUrl}${banner?.secondImageMobile})`);
</script>

<template>
	<section class="registration-bottom">
		<div class="content">
			<AText class="title" :size="{ full: 38, md: 32 }" :modifiers="['uppercase']" as="h1"> {{ banner?.title }} </AText>
			<div class="subtitle">
				<AText
					v-if="isPersonalOffer && entries"
					class="text-primary-400 by-code"
					:size="{ full: 24, md: 32 }"
					:modifiers="['uppercase', 'bold', 'center']"
				>
					<i18n-t keypath="signup.modal.prize.byCode">
						<template #coins>
							<MPrizeFund v-if="coins" variant="coins" icon="coins" is-svg :iconSize="isMobile ? 24 : 16" :offset="0.4">
								{{ numberFormatWithPrefix(coins) }}
							</MPrizeFund>
						</template>
						<template #entries>
							<MPrizeFund
								v-if="entries"
								variant="entries"
								icon="entries"
								is-svg
								:iconSize="isMobile ? 24 : 16"
								:offset="0.4"
							>
								{{ numberFormatWithPrefix(entries) }}
							</MPrizeFund>
						</template>
					</i18n-t>
				</AText>
				<AText
					v-else-if="coins"
					class="text-primary-400"
					:size="{ full: 24, md: 32 }"
					:modifiers="['uppercase', 'bold']"
				>
					<i18n-t keypath="signup.modal.prize.default">
						<template #coins>
							<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="isMobile ? 24 : 16" :offset="0.4">
								{{ numberFormatWithPrefix(coins) }}
							</MPrizeFund>
						</template>
					</i18n-t>
				</AText>
				<AText v-else class="text-primary-400" :size="{ full: 24, md: 32 }" :modifiers="['uppercase', 'bold']">
					{{ banner?.smallTitle }}
				</AText>
				<AText class="text-primary-400" :size="{ full: 24, md: 26 }" :modifiers="['uppercase', 'bold']">
					{{ banner?.subTitle }}
				</AText>
			</div>

			<OHomeForm location-tid="footer-auth" />
		</div>
		<div class="wheel-holder desktop">
			<MWheelOfFortuneAnimated size="400px" />
		</div>
		<NuxtImg
			v-for="(item, index) in [1, 2, 3, 4]"
			:id="`leave-${index + 1}`"
			:key="index"
			class="leaves desktop"
			:src="`/nuxt-img/banners/main-leave-${item}.png`"
			alt="leaves"
			densities="x1"
		/>
		<NuxtImg
			v-for="(item, index) in [1, 2, 3, 4]"
			:id="`mob-leave-${index + 1}`"
			:key="index"
			class="leaves mobile"
			:src="`/nuxt-img/banners/leave-mob-${item}.png`"
			alt="leaves"
			densities="x1"
		/>
	</section>
</template>

<style scoped lang="scss">
.registration-bottom {
	position: relative;
	z-index: 1;
	overflow: hidden;
	padding: 16px;

	@include media-breakpoint-down(md) {
		padding: 24px 16px;
		border-radius: 16px 16px 0 0;
		background: v-bind(bgMobile) no-repeat center center / cover;
	}
	@include media-breakpoint-up(md) {
		border-radius: 16px;
		background: v-bind(bgDesktop) no-repeat center center / cover;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 40px 0;
	}
}
.content {
	.subtitle {
		display: flex;
		align-items: center;
		gap: 6px;
	}
	@include media-breakpoint-down(md) {
		.title {
			max-width: 280px;
			text-align: center;
			margin: 0 auto 12px;
		}
		.subtitle {
			flex-direction: column;
			.by-code {
				width: 300px;
			}
		}
	}
	@include media-breakpoint-up(md) {
		.title,
		.subtitle {
			white-space: nowrap;
		}
		z-index: 3;
		padding-left: 108px;
		@media (max-width: 1255px) {
			padding-left: 32px;
		}
	}
}

.wheel-holder {
	position: relative;
	max-width: 400px;
	transform: translate(25%, 34%) rotate(-12deg);
	z-index: 5;
	@media (max-width: 1120px) {
		right: -20px;
	}
}
.leaves {
	pointer-events: none;
	position: absolute;
	display: block;
	height: auto;
	animation: leaves 4s linear infinite;
	@include media-breakpoint-down(md) {
		z-index: 2;

		&#mob-leave-1 {
			left: 0;
			bottom: 0;
			width: 93px;
		}
		&#mob-leave-2 {
			animation-delay: 2s;
			animation-duration: 5s;
			left: 0;
			bottom: 0px;
			width: 138px;
		}
		&#mob-leave-3 {
			animation-delay: 1s;
			animation-duration: 5s;
			right: 0px;
			bottom: -4px;
			width: 184px;
		}
		&#mob-leave-4 {
			width: 52px;
			right: 0;
			bottom: 81px;
		}
	}
	@include media-breakpoint-up(md) {
		z-index: 0;
		&#leave-1 {
			width: 197px;
			left: -2px;
			top: 15px;
			z-index: 2;
		}
		&#leave-2 {
			animation-delay: 2s;
			animation-duration: 5s;
			width: 184px;
			left: 0px;
		}
		&#leave-3 {
			animation-delay: 1s;
			animation-duration: 5s;
			width: 318px;
			right: 42px;
			top: -1px;
		}
		&#leave-4 {
			width: 200px;
			top: -2px;
			right: -2px;
		}
	}
}
@keyframes leaves {
	50% {
		transform: translateY(7px) rotate(2deg);
	}
}
</style>
